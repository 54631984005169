import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { ProjectRole } from 'src/app/classes/enum/role';
import { TableSurce } from 'src/app/interfaces/message';
import { ChatService } from 'src/app/services/chat-service.service';
import { ProjsectsDataService } from 'src/app/services/projectsData.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @Input() suggestions;
  @Input() isDarkMode: boolean;
  @Input() messageToCopy;
  @Input() file;
  @Input() conversation;
  columns: string[] = [];
  ProjectRole = ProjectRole;
  role: ProjectRole;
  projectData: RouteInfo | undefined;
  @Output() newSuggestion = new EventEmitter()
  isSelectedAll: boolean;
  checkInterval: any;
  pdfStatus: boolean |undefined = true
  constructor(private chatService: ChatService, private projectDataService: ProjsectsDataService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const storedRole = localStorage.getItem('rolesDisplay');
    if (storedRole && Object.values(ProjectRole).includes(storedRole as ProjectRole))
      this.role = storedRole as ProjectRole;
    this.projectData = this.projectDataService.findProjectByProp(
      'rolesDisplay',
      this.role
    );
    if (this.isResearchType(this.role)) {
      if (this.suggestions.length > 0) {
        this.columns = Object.keys(this.suggestions[0]);
        const includeCount = this.suggestions.filter(doc => doc.include).length;
        this.columns = this.columns.filter(column => {
          if (column === 'DocId' || column === 'URL') {
            return false;
          }
          return true;
        });
      }
    }
    console.log(this.suggestions)
    if (this.role == ProjectRole.patentBot) {
      if (this.file.ideation === null) {
        this.pdfStatus = false
        this.checkInterval = setInterval(() => {
          this.pdfIsReady(this.file.id);
        }, 5000);
      }
    }
  }

  ngOnDestroy() {
    // כאשר הקומפוננטה נהרסת, נבטל את ה-setInterval
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  focusOneContex(suggestion) {
    switch (this.role) {
      case ProjectRole.procedureBots:
        this.newSuggestion.emit({ Name: suggestion.Name, DocId: suggestion.DocId });
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
        suggestion.include = !suggestion.include;
        this.cdr.detectChanges();
        break;
    }
  }

  summarize(suggestion) {
    interface Context {
      Name: string,
      DocId: string
    }
    let id: number = Number(this.chatService.conversationId)
    let c: Context = { Name: suggestion.Name, DocId: suggestion.DocId }
    let params = {
      type: "Short",
      context: c,
      conversationId: Number(this.chatService.conversationId)
    }
    this.chatService.summerize(this.projectData!.serverPath.summarize, params)
  }

  getCheckedRows() {
    return this.suggestions.filter(suggestion => suggestion.include)
  }
  selectAll(select: boolean) {
    this.suggestions.forEach(suggestion => {
      suggestion.include = select;
    });
    this.isSelectedAll = !select;
  }
  isResearchType(role: ProjectRole): boolean {
    return role === ProjectRole.researchBot || role === ProjectRole.rotemEngineeringBot || role === this.ProjectRole.agronomistBot || role === this.ProjectRole.fieldTrailChat;
  }

  copy() {
    let textToCopy = this.messageToCopy;
    let tmp = document.createElement('DIV');
    tmp.innerHTML = textToCopy;
    navigator.clipboard.writeText(tmp.textContent || tmp.innerText || '');
  }

  fileIsExel(fileName: string) {
    return fileName.endsWith('.csv') || fileName.endsWith('.xlsx');
  }

  async deleteFile() {
    await this.chatService.deleteFile(this.file.id, 'message')
  }
  async pdfIsReady(id) {
    const params = { id: id }
     await this.chatService.chekPdfExist(params, 'message/checkPdf').then((res)=>{
      this.pdfStatus =res
     })
    if (this.pdfStatus) {
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
        this.checkInterval = null;
      }
    }
  }

  downloadFile(id) {
    const params = {
      fileName: this.file.id + ' - ' + this.file.content
    }
    //.replace(/\.csv$|\.xlsx$|\.pdf$/, '')
    this.chatService.downloadFile(params, 'message/downloadFile').then((base64Data) => {
      const byteCharacters = atob(base64Data); 
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let mimeType
      if (this.file.content.endsWith('.pdf')) {
        mimeType = 'application/pdf';
      } else if (this.file.content.endsWith('.xlsx')) {
        mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (this.file.content.endsWith('.csv')) {
        mimeType = 'text/csv';
      }
      const blob = new Blob([byteArray], { type: mimeType }); 
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = this.file.content
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url); 
    }, error => {
      console.error("Download error:", error);
    });
  }

}
