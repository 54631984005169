import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { ProjectRole } from '../classes/enum/role';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roles: string[] = [];
  private roleSource = new Subject<ProjectRole>();
  public roleSource$ = this.roleSource.asObservable();
  constructor(private authorizationService: AuthorizationService) {}
  getRoles() {
    if (this.roles.length) return this.roles;
    return this.getRoleFromToken();
  }
  getRoleFromToken() {
    type role = {
      roles: string[];
    };
    let authHeader: string | null | undefined =
      this.authorizationService.getToken();
    let decoded: role;
    if (!authHeader) {
      throw new Error('Auth header is not provided');
    } else {
      decoded = jwt_decode(authHeader);
      let roles: string[] = decoded.roles;
      console.log(roles);
      this.roles = roles;
      return roles;
    }
  }
}
