import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { ProjectRole } from 'src/app/classes/enum/role';
import { TableSurce } from 'src/app/interfaces/message';
import { ChatService } from 'src/app/services/chat-service.service';
import { ProjsectsDataService } from 'src/app/services/projectsData.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() suggestions;
  @Input() isDarkMode:boolean;
  @Input() messageToCopy;
  columns: string[] = [];
  ProjectRole = ProjectRole;
  role: ProjectRole;
  projectData: RouteInfo | undefined;
  @Output() newSuggestion = new EventEmitter()
  isSelectedAll: boolean;
  constructor(private chatService: ChatService,private projectDataService: ProjsectsDataService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const storedRole = localStorage.getItem('rolesDisplay');
    if (storedRole &&Object.values(ProjectRole).includes(storedRole as ProjectRole))
      this.role = storedRole as ProjectRole;
    this.projectData = this.projectDataService.findProjectByProp(
      'rolesDisplay',
      this.role
    );
    if (this.role==ProjectRole.researchBot||this.role==ProjectRole.rotemEngineeringBot||this.role==ProjectRole.agronomistBot) {
      if(this.suggestions.length > 0){
        this.columns = Object.keys(this.suggestions[0]);
        const includeCount = this.suggestions.filter(doc => doc.include).length;
        this.columns = this.columns.filter(column =>{
          if (column === 'DocId' || column === 'URL') {
            return false;
          }
          return true;
        });
      }
    }
    else 
    console.log(this.suggestions)
  }
  focusOneContex(suggestion){
    switch (this.role) {
      case ProjectRole.procedureBots:
        this.newSuggestion.emit({Name:suggestion.Name,DocId:suggestion.DocId});
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
        suggestion.include = !suggestion.include;
        this.cdr.detectChanges();
        break;
    }
  }

  summarize(suggestion){
   interface Context {
      Name: string,
      DocId: string
    }
    let id:number=Number(this.chatService.conversationId)
    let c:Context={Name:suggestion.Name,DocId:suggestion.DocId}
    let params ={
      type:"Short",
      context:c,
      conversationId:Number(this.chatService.conversationId)
  }
  this.chatService.summerize(this.projectData!.serverPath.summarize,params)
  }

  getCheckedRows() {
      return this.suggestions.filter(suggestion => suggestion.include)
}
selectAll(select:boolean){
  this.suggestions.forEach(suggestion => {
    suggestion.include=select;
  });
  this.isSelectedAll=!select;
}
isResearchType(role: ProjectRole): boolean {
  return role === ProjectRole.researchBot || role === ProjectRole.rotemEngineeringBot||role===this.ProjectRole.agronomistBot||role===this.ProjectRole.fieldTrailChat;
}

copy() {
  let textToCopy = this.messageToCopy;
  let tmp = document.createElement('DIV');
  tmp.innerHTML = textToCopy;
  navigator.clipboard.writeText(tmp.textContent || tmp.innerText || '');
}
}
