<ng-container [ngSwitch]="true">
  <div *ngSwitchCase="isResearchType(role) && suggestions!= undefined">
    <br><br><b class="source-list-title">{{projectData?.tableTitle['Source']}}</b><br>
    <table>

      <thead>
        <tr>
            <th [ngClass]="{'direction':projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot }">{{projectData?.tableTitle['Focus']}}
            <ng-container>
              <img src="assets/icons/unselectall.png" class="icon" (click)="selectAll(true)" *ngIf="isSelectedAll"> <img
                src="assets/icons/selectall.png" class="icon" (click)="selectAll(false)" *ngIf="!isSelectedAll">
            </ng-container>
          </th>
          <ng-container *ngFor="let column of columns">
            <th [ngClass]="{'direction':projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot }" *ngIf="column!='include'"> {{projectData?.tableTitle[column]}} </th>
          </ng-container>
          <th style="display: none;">Summarize</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let suggestion of suggestions">
          <td [class.includ-research]="suggestion.include" [class.selectedRow]="suggestion.include"
            [class.noinclud-research]="!suggestion.include" id="{{suggestion.DocId}}"
            (click)="focusOneContex(suggestion)">
            <ng-container *ngIf="suggestion.include">
              <img *ngIf="!isDarkMode" src="assets/icons/selected.png">
              <img *ngIf="isDarkMode" src="assets/icons/selected-white1.png">
            </ng-container>
          </td>
          <ng-container *ngFor="let column of columns">
            <td [class.selectedRow]="suggestion.include" *ngIf="column!='include'" [ngSwitch]="column">
              <ng-container *ngSwitchCase="'Name'">
                <a [href]="suggestion['URL']" target="_blank" rel="noopener">{{ suggestion[column] }}</a>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ suggestion[column] }}
              </ng-container>
            </td>
          </ng-container>

          <td class="summarize-td">
            <button class="summarize-sort button-summarize" (click)="summarize(suggestion)"> {{projectData?.tableTitle['Summarize']}} </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngSwitchCase="role==ProjectRole.procedureBots">
    <!-- <div
    *ngIf="message.role != 'user' && showSuggestion ==true   && suggestions.length >= 1"> -->
    <!-- <div *ngIf="message.role != 'user' && suggestions && suggestions.length >1"> -->
    <div class="mt-8 ">
      <div class="flex  ml-1 md:w-full md:m-auto md:mb-4 gap-0 md:gap-2">
        <!-- <div class="h-full flex ml-1 md:w-full md:m-auto md:mb-4 gap-0 md:gap-2 "></div> -->
        <div class="grow">
          <div>Do you mean another procedure? </div>
          <div [ngClass]="{ 'flex-suggestion4':suggestions.length === 4 }"
            class="  w-full  left-0 mb-1 w-full px-1  sm:px-2 sm:pb-0 md:static md:mb-0 md:max-w-none">
            <ng-container *ngFor="let suggestion of suggestions;let i = index">
              <span class="margin span-suggestion" *ngIf="i > 0">
                <button [class.dark-mode]="isDarkMode" (click)="focusOneContex(suggestion)"
                  [class.btn-suggestion]="suggestions.length > 3" [class.borderDark]="!isDarkMode"
                  [class.borderBrieght]="isDarkMode"
                  class="btn relative  btn-neutral group  whitespace-nowrap rounded-xl px-2 py-3 text-left text-token-text-primary md:whitespace-normal">
                  <div class="flex w-full gap-3 items-center justify-center">
                    <div class="flex w-full items-center justify-between">
                      <div class="flex flex-col overflow-hidden">
                        <div class="truncate title">{{suggestion.Name}}</div>
                        <div class="truncate font-normal opacity-50">
                          {{suggestion.DocId}}</div>
                      </div>
                    </div>
                  </div>
                </button>
              </span>
            </ng-container>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div  *ngSwitchCase="role==ProjectRole.patentBot">
    <button class="btn-rm btn-n">
       <img (click)="copy()" [src]="
       isDarkMode
         ? '../../../../assets/icons/copy-w.svg'
         : '../../../../assets/icons/copy.svg'
     " >
    </button>
    <br>
    <br>
  </div>
  <!-- </div> -->
</ng-container>