import { EventEmitter, Injectable } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { environment } from 'src/environments/environment';
import { ProjectRole } from '../classes/enum/role';
import { Context } from '../interfaces/message';
import { ChatService } from './chat-service.service';
import { DarkModeService } from './dark-mode.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AiServices {
  role: ProjectRole;
  public showSuggestion: EventEmitter<boolean> = new EventEmitter(false);
  constructor( 
    private darkModeService: DarkModeService,
    private router: Router,
  ) {
    const storedRole = localStorage.getItem('rolesDisplay');
    if (
      storedRole &&
      Object.values(ProjectRole).includes(storedRole as ProjectRole)
    )
      this.role = storedRole as ProjectRole;
  }

  getParamsByProject(currentMessage: any, context?: Context|null|undefined) {
    let params: {} = {};
    switch (this.role) {
      case ProjectRole.chatGPT:
        break;
      case ProjectRole.procedureBots:
        params = {
          context: context,
        };
        break;
      case ProjectRole.researchBot:
        // params = {
        //   numberOfResearch: parseInt(
        //     localStorage.getItem('numberOfResearch') || '10'
        //   ),
        //   context: currentMessage?.content_source,
        // };
        // break;
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
      case ProjectRole.fieldTrailChat:
        params = {
          numberOfResearch: parseInt(
            localStorage.getItem('numberOfResearch') || '10'
          ),
          context: currentMessage?.content_source,
        };
        break;
    }
    return params;
  }

  async setMessageReceivedByProject(response: any) {
    let result: any = {
      content: response.content,
      role: response.role,
    };
    switch (this.role) {
      case ProjectRole.chatGPT:
        break;
      case ProjectRole.procedureBots:
        result.content_source = response.content_source;
        result.comment = response.comment;
        if (response.suggestions) {
          result.suggestions = response.suggestions;
        }
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
      case ProjectRole.fieldTrailChat:
        result.content_source = response.content_source;
        result.comment = response.comment;
        result.references = response.references;
        break;
      case ProjectRole.patentBot:
        result.ideation = response.ideation
    }
    return result;
  }
  setSummerizeByProject(currentMessage,messageRecived){
    switch (this.role) {
      case ProjectRole.chatGPT:
        break;
      case ProjectRole.procedureBots:
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
      case ProjectRole.fieldTrailChat:
        messageRecived.content_source = currentMessage.content_source;
        messageRecived.comment = currentMessage.comment;
        break;
    }
    return messageRecived;
  }
  async processMessageByProject(message) {
    let procedureNote = '';
    if ((message.role === 'assistant'||message.role==='summerize') && message.content) {
      switch (this.role) {
        case ProjectRole.chatGPT:
          break;
        case ProjectRole.procedureBots:
          if (
            message.content_source !== undefined &&
            message.content_source !== null
          )
            if (Object.keys(message.content_source).length > 0) {
              procedureNote = '';
              procedureNote += `<br><br><b>References:</b><br>`;
              procedureNote += `<div class="ref"><a href="${message.content_source[0].URL}" target="_blank" rel="noopener">${message.content_source[0].Name}</a> `;
              procedureNote += `pages(`;
              const pageNumbers = Object.values(message.content_source).map(
                (item: any) => item.Page
              );
              pageNumbers.sort((a: number, b: number) => a - b);
              const pagesString = pageNumbers.join(',');
              procedureNote += pagesString;
              if (
                pagesString.length > 0 &&
                pagesString[pagesString.length - 1] === ','
              ) {
                procedureNote = procedureNote.slice(0, -1);
              }
              procedureNote += `) <br> </div>`;
              procedureNote += `<br> <p class="comment" >`;
              procedureNote += ` * ${message.comment} </p>`;
              procedureNote = this.replacesplet(procedureNote);
              procedureNote = this.replacesplet(procedureNote);
            }
          break;
        case ProjectRole.researchBot:
        case ProjectRole.rotemEngineeringBot:
        case ProjectRole.agronomistBot:
        case ProjectRole.fieldTrailChat:
          if (
            message.content_source !== undefined &&
            message.content_source !== null
          ) {
            if (message.references !== undefined && message.references !== null){ 
              procedureNote = '';
              if (Object.keys(message.references).length > 0) {
                if(this.role == ProjectRole.rotemEngineeringBot){
                  procedureNote += `<br><br><b class="refBig">מקורות:</b><br>`;
                } else{
                  procedureNote += `<br><br><b class="refBig">References:</b><br>`;
                }
                message.references.forEach((item) => {
                  procedureNote += `<div class="ref"><a href="${item.URL}" target="_blank" rel="noopener">${item.Name}</a> `;

                  const pageNumbers = item.Pages.split(',').map(Number); // Split pages by comma and convert to numbers
                  pageNumbers.sort((a, b) => a - b); // Sort the page numbers
                  const pagesString = pageNumbers.join(',');

                  if(this.role ==ProjectRole.rotemEngineeringBot){
                    procedureNote += `עמודים(${pagesString}) <br> </div>`;
                  } else{
                    procedureNote += `pages(${pagesString}) <br> </div>`;
                  }
                  
                });
              }}
            // procedureNote += `<br><br><b class="source-list-title">Source list:</b><br>`;
            procedureNote = this.replacesplet(procedureNote);
            procedureNote = this.replacesplet(procedureNote);
          }
          break;
        case ProjectRole.patentBot:
          procedureNote += `<br><br> <b class="ideation"> Ideation to questel:</b><br><br>`;
          procedureNote += `${message.ideation}`;
          procedureNote = this.replacesplet(procedureNote);
      }
      message.content = this.replaceCodeBlocks(message.content);
      message.content = this.replacesplet(message.content);
      if (!message.content.includes(procedureNote))
        message.content += procedureNote;
      return {
        ...message,
        content: message.content,
      };
    }
    return message;
  }

  get isDarkMode() {
    return this.darkModeService.isDarkMode && this.router.url != '/';
  }

  suggestionsByProject(chatMessages, message) {
    let suggestions = [];
    switch (this.role) {
      case ProjectRole.chatGPT:
        break;
      case ProjectRole.procedureBots:
        if (chatMessages?.length == 2) {
          suggestions = message.suggestions.slice(1);
        }
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
      case ProjectRole.fieldTrailChat:
        suggestions = message.content_source;
        break;
    }
    return { role: this.role, data: suggestions };
  }
  aciveChatebyProject(message) {
    let suggestions = [];
    switch (this.role) {
      case ProjectRole.chatGPT:
        break;
      case ProjectRole.procedureBots:
        suggestions = [];
        break;
      case ProjectRole.researchBot:
      case ProjectRole.rotemEngineeringBot:
      case ProjectRole.agronomistBot:
      case ProjectRole.fieldTrailChat:
        suggestions = message.content_source;
        // this.showSuggestion.emit()
        break;
    }
    return { role: this.role, data: suggestions };
  }
  replacesplet(content: string): string {
    return content.replace(/\n/g, '<br>');
  }

  replaceCodeBlocks(content: string): string {
    return content.replace(/```([\s\S]*?)```/g, (match, p1) =>
      this.highlightCode(p1, match)
    );
  }

  highlightCode(code: string, match: any): string {
    //return `<code>${hljs.default.highlightAuto(code).value}</code>`;

    return `<code>${code}</code>`;
  }
}
