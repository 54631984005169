import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
} from '@angular/core';
import { ChatService } from '../../services/chat-service.service';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { Router } from '@angular/router';
import { ProjsectsDataService } from 'src/app/services/projectsData.service';
import { ProjectRole } from 'src/app/classes/enum/role';
import { environment } from 'src/environments/environment';
import { AuthorizationService } from 'src/app/services/authorization.service';
import Swal, { SweetAlertPosition } from 'sweetalert2';

import { RouteInfo } from 'src/app/classes/enum/interfaces/RoutInfo';
import { Context, TableSurce } from 'src/app/interfaces/message';
import { SideBarComponent } from '../side-bar/side-bar.component';
import { AiServices } from 'src/app/services/ai-services.service';
import { log } from 'console';
import { TableComponent } from '../shared-component/table/table.component';
// export interface RouteInfo {
//   path: string;
//   title: string;
//   icon: string;
//   environment:any;
//   rolesDisplay: ProjectRole;
// }
@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent implements OnInit, AfterViewInit {
  @ViewChild(SideBarComponent) sideBarComponent: SideBarComponent | undefined;
  activeChat: any;
  isNewChat: boolean = false;
  darkMode = '';
  chatMessages: any = [];
  messageArray: Array<string> = [];
  isUser: boolean = true;
  endScroll: boolean = false;
  chackexamples: boolean | null = null;
  start: boolean = false;
  openSidebar: boolean = false;
  stopWritenMassage: boolean = false;
  isDarkModeCheck: boolean | null =
  this.darkModeService.isDarkMode && this.router.url != '/';
  currentIndex: number = 0;
  currentMessage: any;
  typeCurrentMessage: any;
  intervalId: any;
  projectRole: string | null;
  ProjectRole = ProjectRole;
  isError: boolean = false;
  error!: string;
  projectData: RouteInfo | undefined;
  screenWidth = window.innerWidth;
  currentContext!: Context|null|undefined;
  suggestions!:{role:string,data:any};
  showSuggestion:boolean= false

  @ViewChild('tableComponent', { static: false }) tableComponent: TableComponent;

  @ViewChild('messageContainer', { static: true }) private messageContainer:
    | ElementRef
    | undefined;
  showTable: boolean=false;
  summarizeRecied: boolean=false;

  constructor(
    private chatService: ChatService,
    private darkModeService: DarkModeService,
    private router: Router,
    private projectDataService: ProjsectsDataService,
    private authorizationService: AuthorizationService,
    private aiServices:AiServices,
  ) {
    this.projectRole = localStorage.getItem('rolesDisplay') as ProjectRole;
    this.projectData = this.projectDataService.findProjectByProp(
      'rolesDisplay',
      this.projectRole
    );
    this.chatService.setIsNewChat(true);
    this.changeShowSuggestion()
  }
  ngAfterViewInit(): void {
    this.scrollToBottom();
  }
  async ngOnInit(): Promise<void> {
    if (await this.authorizationService.isLoggedInNewClientId()) {
    } else {
      this.router.navigateByUrl('splash');
    }
    this.darkModeService.setClass();
    this.setActiveChat();
    this.setExamples();
    this.getmessageReceived();
    this.chatService.showex.subscribe((el: any) => {
      this.chackexamples = el;
    });
    this.setError()
  }
  scrollToBottom() {
    if (this.endScroll) {
      let element =
        document.querySelectorAll('#messageContainer')[
        document.querySelectorAll('#messageContainer').length - 1
        ];
      element?.scrollIntoView();
    }
  }

  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      this.endScroll = true;
    } else {
      this.endScroll = false;
    }
  }

  setActiveChat() {
    this.chatService.stopWritenMassage.subscribe(
      (stopWritenMassage: boolean) => {
        this.stopWritenMassage = true;
      }
    );

    this.chatService.isNewChat$.subscribe((isNewChat) => {
      if (isNewChat) {
        this.chatMessages = [];
        this.messageArray = [];
      }
      this.isNewChat = isNewChat;
    });


    this.chatService.activeChatSata$.subscribe(async (newData) => {
      this.endScroll = true;
      if (newData && newData != '') {
        try {
          // Map the messages to promises and wait for all of them to resolve
          this.chatMessages = await Promise.all(
            newData.messages.map(async (message: any) => {
              return await this.aiServices.processMessageByProject(message);
            })
          );
          this.currentMessage=this.chatMessages[this.chatMessages.length-1];
          this.showTable=true
          this.summarizeRecied=false;
          // this.aiServices.aciveChatebyProject(this.chatMessages);
        } catch (error) {
          console.error('Error processing messages:', error);
          // Handle the error if needed
        }
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 150);
    });

    this.chatService.activeChatId.subscribe((res: any) => {
      res.id != -1 ? (this.activeChat = res.id) : (this.activeChat = '');
      if(res.suggestion)
       this.currentContext = res.suggestion
      else 
       this.currentContext = null
      console.log(this.currentContext)
    });
  }
  toremove() {

  }

  getmessageReceived() {
    this.chatService.messageReceived.subscribe((message: any) => {
      if (message) {
        this.setMessages(message);
        !this.chatMessages ? (this.chatMessages = []) : '';
        if (message.role != 'assistant'||message.role!='summerize') {
          this.chatMessages.push(message);
        } else
          this.chatMessages.push({
            content: '',
            role: message.role,
          });
        setTimeout(() => {
          this.scrollToBottom();
        }, 150);
      }
      this.summarizeRecied=false;
    });
    this.chatService.summarizeRecied.subscribe((message: any) => {
      this.summarizeRecied=true;
    })
  }

  async setExamples() {
    let isexamples = await localStorage.getItem('isexamples');
    if (isexamples == 'disabled') {
      this.chackexamples = true;
    } else {
      this.chackexamples = false;
    }
    localStorage.setItem('isexamples', 'enabled');
  }

  get isDarkMode() {
    return this.darkModeService.isDarkMode && this.router.url != '/';
  }

  openSid() {
    this.openSidebar = !this.openSidebar;
  }

  onClickedOutside() {
    this.openSidebar = false;
  }

  async setMessages(message: any) {
    console.log(message)
    if (message.role == 'assistant'||message.role==='summerize') {
      if (message.content) {
       message=await this.aiServices.processMessageByProject(message)
      }
      if(message.role==='summerize'){
        message=await this.aiServices.setSummerizeByProject(this.currentMessage,message);
        message=await this.aiServices.processMessageByProject(message)
      }
      this.currentIndex = 0;
      this.currentMessage = message;
      this.typeCurrentMessage =message;
      this.stopWritenMassage = false;
      this.typeText();
      this.chackexamples = false;
      this.suggestions=this.aiServices.suggestionsByProject(this.chatMessages,message)
    }
    const chatContainer = document.querySelector('.chat-window');
    chatContainer && this.endScroll == true
      ? (chatContainer.scrollTop = chatContainer.scrollHeight)
      : null;
  }

  typeText() {
    this.showTable=false
    let currentTime = Date.now();
    let elapsedTime = 0;
    const duration = 8; // Set a lower duration for faster typing
  
    const animate = () => {
      const now = Date.now();
      const deltaTime = now - currentTime;
      elapsedTime += deltaTime;
      currentTime = now;
  
      while (elapsedTime >= duration) {
        this.currentIndex++;
        elapsedTime -= duration;
      }
  
      this.chatMessages[this.chatMessages.length - 1].content = this.typeCurrentMessage?.content.slice(0, this.currentIndex);
      this.scrollToBottom();
      const chatContainer = document.querySelector('.chat-window');
      const isAtBottom = chatContainer
        ? chatContainer.scrollHeight - chatContainer.clientHeight <= chatContainer.scrollTop + 1
        : false;

      // If not at the bottom, scroll to show the latest content
      if (chatContainer && !isAtBottom) {
        // console.log("isAtBottom")
        chatContainer.scrollTop = chatContainer.scrollHeight ?? 0;
      }
  
      if (this.currentIndex < this.typeCurrentMessage.content.length) {
        requestAnimationFrame(animate);
      } else {
        this.chatService.stopWritenMassage.emit();
        const chatContainer = document.querySelector('.chat-window');
        chatContainer && this.endScroll == true
          ? (chatContainer.scrollTop = chatContainer.scrollHeight)
          : null;
          this.showSuggestion = true;
          this.showTable=true
      }
    };
  
    animate();
  }

  getsrcByRole(role: string, darkMode: string): string {
    let src = '';
    if (darkMode === 'disabled') {
      src = role == 'user' ? 'assets/account.png' : 'assets/AI.png';
      this.isUser = role == 'user' ? true : false;
    } else {
      src =
        role == 'user'
          ? 'assets/images/dark/user.svg'
          : `${this.projectData?.iconChatAssistent}`;
      this.isUser = role == 'user' ? true : false;
    }
    return src;
  }
  createMailtoLink(message: any) {
    let toEmail = '';
    let subject = 'ICL ChatGPT';
    let emailContent = message.content;
    let encodedContent = encodeURIComponent(emailContent);
    let mailtoLink =
      'mailto:' + toEmail + '?subject=' + subject + '&body=' + encodedContent;
    // window.location.href = mailtoLink;
    window.open(mailtoLink, '_blank');
  }

  copy(message: any) {
    let textToCopy = message.content;
    let tmp = document.createElement('DIV');
    tmp.innerHTML = textToCopy;
    navigator.clipboard.writeText(tmp.textContent || tmp.innerText || '');
  }
  sendExamples(mes:string) {
    if (this.projectData)
      this.chatService.sendMessage(mes,'',this.projectData.serverPath.sendMessage,this.currentMessage,this.currentContext);
  }
  sendExampleInRotem(){
    this.chatService.sendExampleInRotem(this.projectData?.serverPath.getResearchBooks, "אילו ספרי מחקר נכללים בצ'אט?")
  }

  setError() {
    this.chatService.setError.subscribe((res: string) => {
      switch (res) {
        case 'We had a problem. Please try again in a few minutes':
          this.chatService.stopWritenMassage.emit();
          let positionClass = '';
          if (this.screenWidth >= 1200) {
            positionClass = 'top-end';
          } else {
            positionClass = 'bottom-left';
          }
          Swal.fire({
            toast: true,
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            icon: 'error',
            title: res,
            position: positionClass as SweetAlertPosition,
          });
          break;
        case 'error creating new conversation':
        case 'error creating new message':
        case 'Error when loading conversations':
        case 'We had a problem. Please try again in a few minutes':
        case 'Your token has expired please go back to launchpad':
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res,
          });
          this.chatService.stopWritenMassage.emit();
          if (res === 'error creating new conversation') {
            this.chatMessages = [];
          }
          break;
        default:
          if (res.includes('user')) {
            this.chatService.stopWritenMassage.emit();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res,
            });
            this.chatMessages = [];
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: res,
            });
          }
          break;
      }

    })
  }

  changeProcedure(event){
    let Name:string=event.Name, DocId: string=event.DocId
    if(this.projectRole==ProjectRole.procedureBots){
      Name = Name.toLowerCase()
    if(this.currentContext){
    this.currentContext.DocId = DocId;
    this.currentContext.Name = Name;
    }
    this.chatService.context = this.currentContext;
    this.chatService.deleteLastMessage(this.projectData!.serverPath.deleteLastMessage)
    this.showSuggestion = false;
    this.chatMessages.pop()
    this.suggestions = {role:'',data:[]};
    this.chatService.changeContext(this.projectData!.serverPath.changeContext, Name, DocId)//only for procedures
    this.chatService.sendMessage(this.chatMessages[0].content,this.chatService.conversationId,this.projectData!.serverPath.sendMessage,this.currentMessage,this.currentContext);
    this.chatMessages.pop()
    this.chatService.editTitle(Name, this.projectData!.serverPath.editChat)
    this.sideBarComponent?.changeProcedure(Name)
  }
  }
  changeShowSuggestion(){
    this.chatService.hideSuggestion.subscribe(()=>{
      this.showSuggestion = false
      this.suggestions = {role:'',data:[]};
    })
    // this.aiServices.showSuggestion.subscribe(()=>{
    //   this.showSuggestion = true
    // })
  }
  userSendMessage(userInput){
    if((this.projectRole==ProjectRole.researchBot||this.projectRole==ProjectRole.rotemEngineeringBot||this.projectRole==ProjectRole.agronomistBot)&&this.activeChat){
      const dataFromChild = this.tableComponent.getCheckedRows();
      this.currentMessage.content_source=dataFromChild
    }
    this.chatService.sendMessage(userInput,  this.activeChat,this.projectData!.serverPath.sendMessage,this.currentMessage,this.currentContext);
}

isResearchType(role: string | null): boolean {
  return role === ProjectRole.researchBot || role === ProjectRole.rotemEngineeringBot||role===this.ProjectRole.agronomistBot||role===this.ProjectRole.fieldTrailChat;
}
}
