<div [class.dark-mode]="isDarkMode" class="overflow-hidden w-full h-full relative flex z-0 full-screen">
  <div class="relative flex h-full max-w-full flex-1 overflow-hidden">
    <div class="flex h-full max-w-full flex-1 flex-col w-full">
      <app-header></app-header>
      <div class="main-rap" [class.dark-mode]="isDarkMode">
        <app-side-bar #myElement [ngClass]="{ show: openSidebar }" [class.dark-mode]="isDarkMode"></app-side-bar>
        <div class="main-warp relative h-full w-full transition-width flex flex-col overflow-auto items-stretch flex-1"
        [ngClass]="{'dir': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}">
          <main
            class="main-back relative h-full w-full transition-width flex flex-col overflow-auto items-stretch flex-1">
            <div class="h-100 flex-1 overflow-hidden main-back pd-tp">

              <div class="h-100 react-scroll-to-bottom--css-hjsmp-79elbk dark:bg-gray-800">
                <div class="h-100 react-scroll-to-bottom--css-hjsmp-1n7m0yu">
                  <div (scroll)="onScroll($event)"
                  [ngClass]="{'scroll-window': chatMessages.length !== 0, 'chat-window-height': chatMessages.length === 0, 'chat-window-procedure': projectData?.title === 'Procedure Chat'}"
                    class="chat-window flex flex-col text-sm dark:bg-gray-800">

                    <div
                      class="h-100 ex-box text-gray-800 w-full mx-auto md:max-w-2xl lg:max-w-3xl md:flex md:flex-col px-6 dark:text-gray-100"
                      *ngIf="
                        chatMessages &&
                        chatMessages.length == 0
                        ">
                      <div class="app-container md:flex items-start gap-3.5 h-100">
                        <div class="flex-1 overflow-hidden h-100 wrap-title">
                          <div class="flex h-full flex-col items-center justify-center h-100">
                            <div class="h1 text-4xl font-semibold mt-6 sm:mt-[20vh] sm:mb-16 flex gap-2">
                              Hello, I’m {{projectData?.title!}}
                            </div>
                            <p class="fmessage-p">
                              It's AI time! Here it is safe to ask and chat about anything related to your line of work.
                            </p>
                            <p class="fmessage-p text-center">Join the future!</p>
                          </div>
                        </div>
                        <div class="mt-8">
                          <div class="h-full flex ml-1 md:w-full md:m-auto md:mb-4 gap-0 md:gap-2 justify-center example">
                            <div class="grow">
                              <div *ngIf="projectRole==ProjectRole.procedureBots || projectRole==ProjectRole.rotemEngineeringBot"
                                class=" w-full left-0 mb-1 w-full px-1 pr sm:px-2 sm:pb-0 md:static md:mb-0 md:max-w-none">
                                <span>
                                  <button [class.dark-mode]="isDarkMode"
                                  (click)="projectRole === ProjectRole.rotemEngineeringBot ? sendExampleInRotem() : null"
                                    class="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                                    <div class="flex w-full gap-2 items-center justify-center">
                                      <div class="flex w-full items-center justify-between">
                                        <div class="flex flex-col overflow-hidden">
                                          <div *ngIf="this.projectRole==ProjectRole.procedureBots">
                                          <div class="truncate title">Pay attention</div>
                                          <div class="truncate font-normal opacity-50">Attention: for a new topic please open a new chat.</div>
                                          </div>
                                          <div *ngIf="this.projectRole==ProjectRole.rotemEngineeringBot">
                                            <div class="truncate title dir">ספרי ידע</div>
                                            <div class="truncate font-normal opacity-50">אילו ספרי מחקר נכללים בצ'אט?</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </button>
                                </span>
                              </div>
                              <div
                                class="absolute bottom-full left-0 mb-4 flex w-full grow gap-2 px-1 pb-1 sm:px-2 sm:pb-0 md:static md:mb-0 md:max-w-none">
                                <div class="grid w-full grid-flow-row gap-2 grid-wrap">
                                  <div class="flex flex-col gap-2 col-wrap ml">
                                    <span>
                                      <button [class.dark-mode]="isDarkMode"
                                        (click)="sendExamples(projectData?.exapmles[0].content)"
                                        [ngClass]="{'dir': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}"
                                        class="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                                        <div class="flex w-full gap-2 items-center justify-center">
                                          <div class="flex w-full items-center justify-between">
                                            <div class="flex flex-col overflow-hidden">
                                                <div class="truncate title">{{projectData?.exapmles[0].title}}</div>
                                                <div class="truncate font-normal opacity-50"><dfn [attr.title]="projectData?.exapmles[0].content">{{projectData?.exapmles[0].content}}</dfn></div>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </span>
                                    <span>
                                      <button [class.dark-mode]="isDarkMode"
                                        (click)="sendExamples(projectData?.exapmles[1].content)"
                                        [ngClass]="{'dir': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}"
                                        class="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                                        <div class="flex w-full gap-2 items-center justify-center">
                                          <div class="flex w-full items-center justify-between">
                                            <div class="flex flex-col overflow-hidden">
                                                <div class="truncate title">{{projectData?.exapmles[1].title}}</div>
                                                <div class="truncate font-normal opacity-50"><dfn [attr.title]="projectData?.exapmles[1].content">{{projectData?.exapmles[1].content}}</dfn></div>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </span>
                                  </div>
                                  <div class="flex flex-col gap-2 col-wrap nth-2">
                                    <span>
                                      <button [class.dark-mode]="isDarkMode"
                                        (click)="sendExamples(projectData?.exapmles[2].content)"
                                        [ngClass]="{'dir': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}"
                                        class="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                                        <div class="flex w-full gap-2 items-center justify-center">
                                          <div class="flex w-full items-center justify-between">
                                            <div class="flex flex-col overflow-hidden">
                                                <div class="truncate title">{{projectData?.exapmles[2].title}}</div>
                                                <div class="truncate font-normal opacity-50"><dfn [attr.title]="projectData?.exapmles[2].content">{{projectData?.exapmles[2].content}}</dfn></div>
                                            </div>

                                          </div>
                                        </div>
                                      </button>
                                    </span>
                                    <span>
                                      <button [class.dark-mode]="isDarkMode"
                                        (click)="sendExamples(projectData?.exapmles[3].content)"
                                        [ngClass]="{'dir': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}"
                                        class="btn relative btn-neutral group w-full whitespace-nowrap rounded-xl px-4 py-3 text-left text-token-text-primary md:whitespace-normal">
                                        <div class="flex w-full gap-2 items-center justify-center">
                                          <div class="flex w-full items-center justify-between">
                                            <div class="flex flex-col overflow-hidden">
                                                <div class="truncate title">{{projectData?.exapmles[3].title}}</div>
                                                <div class="truncate font-normal opacity-50"><dfn [attr.title]="projectData?.exapmles[3].content">{{projectData?.exapmles[3].content}}</dfn></div>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-container>
                      <div *ngFor="let message of chatMessages; index as i"
                        [ngClass]="{ 'message-ai': message.role != 'user' }"
                        class="chatMessage group w-full text-gray-800 dark:text-gray-100 dark:bg-gray-800"
                        id="messageContainer" #messageContainer>
                        <div class="app-container"
                        [ngClass]="{'paddingRotem': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}">
                          <div class="btn-copy-share" *ngIf="message.role != 'user'"
                          [ngClass]="{'leftButton': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}">
                            <button class="btn-rm btn-n" (click)="createMailtoLink(message)">
                              <img [src]="
                                  isDarkMode
                                    ? '../../../assets/icons/share-w.svg'
                                    : '../../../assets/icons/share.svg'
                                " />
                            </button>
                            <button class="btn-rm btn-n" (click)="copy(message)">
                              <img [src]="
                                  isDarkMode
                                    ? '../../../assets/icons/copy-w.svg'
                                    : '../../../assets/icons/copy.svg'
                                " />
                            </button>
                          </div>
                          <div
                            class="chatMessage-box flex p-3 gap-3 text-base md:gap-6 md:max-w-2xl lg:max-w-[38rem] xl:max-w-3xl md:py-6 lg:px-0 m-auto"
                            [ngClass]="{'marginRotem': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot && message.role =='user'}">
                            <div class="flex-shrink-0 flex flex-col relative items-end">
                              <div class="w-30px">
                                <div class="relative flex">
                                  <span class="user">
                                    <img [src]="
                                        getsrcByRole(message.role, darkMode)
                                      " />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="w-full relative flex w-[100%] flex-col gap-1 md:gap-3 lg:w-[100%]">
                              <div class="flex flex-grow flex-col gap-3">
                                <div
                                  class="min-h-[20px] flex items-start overflow-x-auto whitespace-pre-wrap break-words flex-col gap-4">
                                  <div [ngClass]="{
                                      'typewriter monospace big-caret lorem':
                                        message.role != 'user',
                                      'user-question': message.role == 'user'
                                    }">

                                    <span
                                      [innerHTML]="(message.role !== 'user' && typeCurrentMessage && typeCurrentMessage?.id == message.id && i == chatMessages.length -1 )  ? (message.content | typingEffect:currentIndex) : message.content "
                                      typingAnimation>
                                    </span>
                                    <ng-container *ngIf="showTable && message.role != 'user' && (projectRole==ProjectRole.patentBot)">
                                      <app-table [isDarkMode]="isDarkMode" [messageToCopy]="message.ideation">
                                      </app-table>
                                    </ng-container>
                                    <ng-container *ngIf="i == chatMessages.length -1&&showTable">
                                      <app-table #tableComponent *ngIf="message.role != 'user'&& isResearchType(projectRole)" [suggestions]="currentMessage.content_source" [isDarkMode]="isDarkMode" (newSuggestion)="changeProcedure($event)" >
                                      </app-table>
                                      <app-table #tableComponent *ngIf="message.role != 'user'&&  (projectRole==ProjectRole.procedureBots) &&currentMessage.suggestions!=undefined" [suggestions]="currentMessage.suggestions" [isDarkMode]="isDarkMode" (newSuggestion)="changeProcedure($event)" >
                                      </app-table>    
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="message.role !== 'user' &&i == chatMessages.length-1&&showTable"><p class="message-bottom" [ngClass]="{'alignRotem': projectData?.rolesDisplay == ProjectRole.rotemEngineeringBot}">* We use AI. Ai can make mistakes. If the information is important. please double check.</p></ng-container>
                      </div>

                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <app-user-input class="pd-bt" (userSendMessage)="userSendMessage($event)"></app-user-input>
          </main>
        </div>
      </div>
    </div>
  </div>
</div>
