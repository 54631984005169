import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import Chat from '../interfaces/chat';
import { BehaviorSubject, take } from 'rxjs';
import { Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { Context } from '../interfaces/message';
import { AiServices } from './ai-services.service';
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public messageReceived: EventEmitter<any> = new EventEmitter();
  public summarizeRecied: EventEmitter<any> = new EventEmitter();
  public messageStopWriten: EventEmitter<string> = new EventEmitter();
  public messageres: EventEmitter<any> = new EventEmitter();
  private messages: Array<any> = [];
  public stopWritenMassage: EventEmitter<boolean> = new EventEmitter(false);
  public showex: EventEmitter<boolean> = new EventEmitter(false);
  public conversationId: string = '';
  public chats: Array<any> = [];
  private activeChat = new BehaviorSubject<any>(null);
  activeChatSata$ = this.activeChat.asObservable();
  public activeChatId: EventEmitter<{}> = new EventEmitter();
  public setError: EventEmitter<string> = new EventEmitter();
  public removeContext: EventEmitter<string> = new EventEmitter();
  public hideSuggestion: EventEmitter<boolean> = new EventEmitter(false);
  public changeTitle: EventEmitter<string> = new EventEmitter();
  private isNewChat = new BehaviorSubject<any>(null);
  isNewChat$ = this.isNewChat.asObservable();
  isNewChatIn: boolean = false;
  context: Context | null | undefined = undefined
  constructor(private httpService: HttpHandlerService, private router: Router, private authorizationService: AuthorizationService, private aiServices: AiServices) { }

  messageStopWritenBTN() {
    this.messageStopWriten.emit();
    this.stopWritenMassage.emit(true)
  }

  getAllUsersChats(url: string) {
    const res: any = this.httpService.get(url)
    if (res.status == 403) {
      if (this.authorizationService.isLoggedInNewClientId() == true)
        this.setError.emit("We had a problem. Please try again in a few minutes")
      else
        this.setError.emit("Your token has expired please go back to launchpad")

    }
    return res
  }

  //when user enter an old chat 
  async setActiveChat(url: string) {
    this.stopWritenMassage.emit()
    // @ts-ignore
    await this.httpService.get(url)
      .subscribe(
        (response: any) => {
          if (response.status == 403) {
            if (this.authorizationService.isLoggedInNewClientId() == true)
              this.setError.emit("We had a problem. Please try again in a few minutes")
            else this.setError.emit("Your token has expired please go back to launchpad")
          }
          console.log("response -- as  activeChat", response)
          this.context = response.context;
          this.conversationId = response.id
          response && response.id && this.activeChatId.emit({ id: response.id, suggestion: response.context })
          response && this.activeChat.next(response);
        },
        (error: any) => {
          // Handle error
          console.error('Error:', error);
        }
      );
  }

  setIsNewChat(val: boolean) {
    this.isNewChat.next(val);
    val == true ? this.activeChatId.next({ id: -1, suggestion: null }) : "";
    this.isNewChatIn = val;
    if (val) {
      this.messages = []
      this.chats = []
    }
    this.hideSuggestion.emit()
    this.context = undefined
  }


  editChat(chat: Chat, url: string) {
    let res = true;
    this.httpService.put(`${url}/${chat.id}`, { name: chat.title }).subscribe(
      (response: any) => {
        
        res = true;
        if (response.status == 403) {
          res = false
        }
      },
      (error) => {
        res = false;
        // Handle error
      }
    );
    return res;
  }


 
  removeChat(chat: Chat, url: string) {
    //return  this.httpService.delete<any>(`message/${chat.id}` )
    const res: any = this.httpService.delete(`${url}/${chat.id}`)
    if (res.status == 403) {
      if (this.authorizationService.isLoggedInNewClientId() == true)
        this.setError.emit("We had a problem. Please try again in a few minutes")
      else
        this.setError.emit("Your token has expired please go back to launchpad")
    }
    return res
  }

  async sendMessage(userInput: string, activeChat: any, url: string, currentMessage: any, context?: Context | null | undefined): Promise<void> {
    let params = {}
    // if(activeChat)
    params = this.aiServices.getParamsByProject(currentMessage, context)
    this.messages.push({ role: "user", content: userInput })
    this.messageReceived.emit({ role: "user", content: userInput })
    if (activeChat) {
      this.conversationId = activeChat;
    } else {
      this.conversationId = '';
    }
    try {
      this.messages = this.messages.slice(Math.max(this.messages.length - 5, 0))
      this.httpService
        .post(`${url}/${this.conversationId}`, { message: this.messages, context: context, params: params }).pipe(take(1))
        .subscribe((result: any) => {
          console.log("result: ,", result)
          if (this.isNewChatIn && result.id) {
            // this.changeTitle.emit(result.title)
            if (result.messages[0].role == 'assistant') {
              this.activeChatId.emit({ id: result.id, suggestion: result.messages[0].suggestions ? result.messages[0].suggestions[0] : null });
              this.context = result.messages[0].suggestions ? result.messages[0].suggestions[0] : null;
            } else {
              this.activeChatId.emit({ id: result.id, suggestion: result.messages[1].suggestions ? result.messages[1].suggestions[0] : null });
              this.context = result.messages[1].suggestions ? result.messages[1].suggestions[0] : null;
            }
            this.conversationId = result.id
          }
          if (result.status == 'failed') {
            if (this.isNewChatIn && result.conversation?.id) {
              this.activeChatId.emit({ id: result.conversation.id, suggestion: null })
            }
            this.setError.emit(result.message)
          } else {
            // if (result.title)
            //   this.changeTitle.emit(result.title)
            this.setMessageReceived(result);
            // this.messageres.emit(result)
          }
          this.messageres.emit(result)
        }, (error: any) => {
          if (this.authorizationService.isLoggedInNewClientId() == true)
            this.setError.emit("We had a problem. Please try again in a few minutes")
          else
            this.setError.emit("Your token has expired please go back to launchpad")
        })
    }
    catch (error) {

      console.log(error);
      this.setMessageReceived(error);
    }

  }
  async setMessageReceived(result: any) {
    //first convertasion
    if (result.messages) {
      for (const choice of result.messages) {
        if (choice.role === "assistant" && choice !== undefined) {
          try {
            const result = await this.aiServices.setMessageReceivedByProject(choice);
            this.messages.push(result);
            this.messageReceived.emit(result);
          } catch (error) {
            console.error('Error:', error);
          }
        }
      }
    } else {
      this.messages.push(result)
      this.messageReceived.emit(result)
    }
    this.setIsNewChat(false)
  }


  splitIntoParagraphs(text: string, delimiter = '\n\n'): Array<string> {
    const paragraphs = text.split(/(?:\r?\n)+/); // delimiter .split(/(?:\r?\n)+/)
    return paragraphs;
  }
  deleteLastMessage(url:string){
    this.messages = []
    this.httpService.delete(`${url}/${this.conversationId}`).subscribe((res) => {
      console.log(res)
    }
    )
  }
  editTitle(title: string, url: string) {
    let res = true;
    this.httpService.put(`${url}/${this.conversationId}`, { name: title }).subscribe(
      (response: any) => {
        
        res = true;
        if(response.status == 403){
          res = false
        }
      },
      (error) => {
        res = false;
        // Handle error
      }
    );
    return res;
  }

  changeContext(url: string, name: string, DocId: string) {
    const context: Context = { Name: name, DocId: DocId }
    this.httpService.put(`${url}/${this.conversationId}`, { context: context }).subscribe((result: any) => {
      console.log(result)
    })
  }
  summerize(url, params) {
    this.messageReceived.emit({ role: "user", content: "Thanks for your question! Our AI is working hard to provide the best answer. Please bear with us—it might take a moment" })
    this.httpService
      .post(`${url}/`, { params: params }).pipe(take(1))
      .subscribe((response: any) => {
        let result: any = {
          content: response.content,
          role: "summerize",
        };
        this.messageReceived.emit(result)
        this.summarizeRecied.emit();
      })
  }
  sendExampleInRotem(url, userInput){
    this.messages.push({ role: "user", content: userInput })
    this.messageReceived.emit({ role: "user", content: userInput })
    try {
      this.httpService
        .post(url, {}).pipe(take(1))
        .subscribe((result: any) => {
          console.log("result: ,", result)
          if (result.status == 'failed') {
            this.setError.emit(result.message)
          } else {
            this.setMessageReceived(result);
          }
          this.messageres.emit(result)
        }, (error: any) => {
          if (this.authorizationService.isLoggedInNewClientId() == true)
            this.setError.emit("We had a problem. Please try again in a few minutes")
          else
            this.setError.emit("Your token has expired please go back to launchpad")
        })
    }
    catch (error) {
      console.log(error);
      this.setMessageReceived(error);
    }

  }
}
