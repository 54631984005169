import { Injectable } from '@angular/core';
import { ProjectRole } from '../classes/enum/role';
import { RouteInfo } from '../classes/enum/interfaces/RoutInfo';
import { BaseUri } from '../classes/enum/baseUri';
@Injectable({
  providedIn: 'root',
})
export class ProjsectsDataService {
  projectRole:ProjectRole | undefined;
  allRols: any;
  activateRoute: any;
  constructor() {}
  projectList: RouteInfo[] = [
    {      
      path: '/procedures-bots',
      title: 'Procedure Chat',
      version: '1.0.0',
      icon: 'procidure-bots-icon.png',
      environment:'procedureBotsEnvirpnment',
      rolesDisplay: ProjectRole.procedureBots,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'message',summarize:'', getResearchBooks:''},
      iconChatAssistent:'assets/images/procidure-bots-icon.png',
      logoChat:'Procedures',
      baseUri:BaseUri.procedureBot,
      newButton:'New Chat',
        exapmles:[
        {title:'Finance',content:'How many approvals are required for a purchase order request totaling $50,001 in ICL?'},
        {title:'Travel',content:'What documentation is necessary for employees to submit travel expense reimbursements?'},
        {title:'Safety',content:'How often are safety drills conducted to ensure everyone is prepared?'},
        {title:'CAPEX (Capital Expenditure)',content:'How many levels of authorization are needed for a capital expenditure project worth $50,000, and what documentation is required at each stage of approval?'}
    ]
    },
    {
      path: '/chat-message',
      title: 'ICL ChatGPT',
      version: '1.0.0',
      icon: 'chatgpt-icon.png',
      environment:'chatGPTEnvironment',
      rolesDisplay: ProjectRole.chatGPT,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'',deleteLastMessage:'',summarize:'', getResearchBooks:''},
      iconChatAssistent:'assets/images/dark/assistrant.png',
      logoChat:'ChatGPT',
      baseUri:BaseUri.chatGpt,
      newButton:'New Chat',
      exapmles:[
        {title:'Management',content:'As an IT manager, describe ways to prioritize tasks?'},
        {title:'Programming',content:'please write a python code for a "Hello world" script.'},
        {title:'Communication',content:'How can we improve our email communication within the department?'},
        {title:'Time Management',content:'What are some effective ways to manage time during meetings?'}
      ]
     },
     {
      path: '/research-bot',
      title: 'Research Chat',
      version: '1.0.0',
      icon: 'research-bots-icon.png',
      environment:'researchBotEnvirpnment',
      rolesDisplay: ProjectRole.researchBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      baseUri:BaseUri.researchBot,
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Researches',
      newButton:'New Chat',
      exapmles:[
        {title:'solvent extraction',content:'What solvents can be used to separate CaBr2 from aqueous solutions by solvent extraction?'},
        {title:'flame retardants',content:'Which flame retardants were tested for rigid or flexible polyurethanes?'},
        {title:'Ion exchange technology with sodium',content:'What works were done on ion exchange technology that involve sodium (removal or extraction)?'},
        {title:'Sodium : removal or extraction',content:'What is the typical temperature range for bromination of pentabromobenzyl monoacrylate?'}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}

     },
     {
      path: '/rotem-engineering-bot',
      title: 'Rotem Engineering Chat',
      version: '1.1.1',
      // icon: 'rotem-engineering-bot-icon.png',
      icon: 'rotem-icon.png',
      environment:'rotemEngineeringBotEnvirpnment',
      rolesDisplay: ProjectRole.rotemEngineeringBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:'message/getResearchBooks'},
      baseUri:BaseUri.rotemEngineeringBot,
      // iconChatAssistent:'assets/images/rotem-engineering-bot.png',
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Rotem Engineering',
      newButton:'New Chat',
      exapmles:[
        {title:'סוגי פלדות',content:'מה ההבדל בין פלדה SS316 לבין G30?'},
        {title:'מערכות הנעה הידראולוגיות',content:'מה זה מערכת הנעה הידראולית?'},
        {title:'סוגי ממסרות ברותם',content:'איזה סוגי ממסרות קיימות ברותם?'},
        {title:'מסועי סרט',content:'תסביר לי סיבות אפשריות לסטיות של מסועי סרט.'}     
      ],
      tableTitle:{Source:'אולי יעניין אותך גם?',Summarize:'סיכום',Focus:'פוקוס',Name:'שם', BU:'חטיבה', Author:'עורך'}

     },
     {
      path: '/agronomist-bot',
      title: 'Agronomist research Chat ',
      version: '1.0.0',
      // icon: 'rotem-engineering-bot-icon.png',
      icon: 'agronomis-icon.png',
      environment:'agronomistBotEnvirpnment',
      rolesDisplay: ProjectRole.agronomistBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      baseUri:BaseUri.agronomistBot,
      iconChatAssistent:'assets/images/research-bots-icon.png',
      logoChat:'Agronomist research',
      newButton:'New Chat',
      exapmles:[
        {title:'Phosphogypsum',content:'. What is the heavy metals and radioactive elements concentration in phosphogypsum?'},
        {title:'Si in plants',content:'What is the role of silicon in plants?'},
        {title:'Biostimulants in agriculture',content:'What is the contribution of biostimulants to crop production?'},
        {title:'Allowed metal ',content:'What are the allowed heavy metal concentrations in fruits and vegetables? '}
      ],
      tableTitle:{Source:'Source list:',Summarize:'Summarize',Focus:'Focus',Name:'Name', BU:'BU', Author:'Author'}

     },
     {
      path: '/patent-bot',
      title: 'Patent Chat',
      version: '1.0.0',
      icon: 'patent-caht-icon.png',
      environment:'patentBotEnvirpnment',
      rolesDisplay: ProjectRole.patentBot,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      baseUri:BaseUri.patentBot,
      iconChatAssistent:'assets/images/patent-caht-icon.png',
      logoChat:'Patent chat',
      newButton:'New Project',
      exapmles:[
        {title:'Magnesium',content:'Offer me an idea about developing a magnesium patent'},
        {title:'Lithium Bromide',content:'Offer me an idea about developing a patent for the preparation of lithium bromide'},
        {title:'Kieserite and Gran',content:'Offer me an idea about developing a patent on Kieserite and Gran'},
        {title:'Textile recycling',content:'Offer me an idea about developing a patent for a textile cycle'}
      ]

     },
     {
      path: '/field-trail-chat',
      title: 'Field Trail Chat',
      version: '1.0.0',
      icon: 'field-trials-caht-icon.png',
      environment:'fieldTrailChatEnvirpnment',
      rolesDisplay: ProjectRole.fieldTrailChat,
      serverPath:{getAllUsersChats:'conversation',setActiveChat:'conversation',editChat:'conversation',removeChat:'conversation',sendMessage:'message', changeContext:'conversation/context',deleteLastMessage:'conversation',summarize:'message/summerize', getResearchBooks:''},
      baseUri:BaseUri.agronomistBot,
      iconChatAssistent:'assets/images/field-trials-caht-icon.png',
      logoChat:'Field Trail Chat',
      newButton:'New Chat',
      exapmles:[
        {title:'Magnesium',content:'Offer me an idea about developing a magnesium patent'},
        {title:'Lithium Bromide',content:'Offer me an idea about developing a patent for the preparation of lithium bromide'},
        {title:'Kieserite and Gran',content:'Offer me an idea about developing a patent on Kieserite and Gran'},
        {title:'Textile recycling',content:'Offer me an idea about developing a patent for a textile cycle'}
      ]
     }
  ];
  getAllData() {
    return this.projectList;
  }
  findProjectByProp(propName: string, propValue: any): RouteInfo | undefined {
    return this.projectList.find((project) => project[propName] === propValue);
    }
    setRols(roles){
      this.allRols=roles;
    }
    getAllRols(){
      return this.allRols;
    }
  setProjectRole(role:ProjectRole){
    this.projectRole=role;
  }
  getProjectRole(){
    return this.projectRole;
  }
  setActivateRoute(route){
    this.activateRoute=route;
  }
  getActivateRoute(){
    return this.activateRoute
  }
}
