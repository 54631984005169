import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { PostauthComponent } from './components/postauth/postauth.component';
import { LaunchpadComponent } from './components/launchpad/launchpad.component';
import { ProceduresBotsComponent } from './components/procedures-bots/procedures-bots.component';
import { ProjectRole } from './classes/enum/role';
import { CheckRoleGuard } from './guards/CheckRoleGuard.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SplashComponent } from './components/splash/splash.component';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', component: PostauthComponent ,pathMatch: 'full'},
  { path: 'splash', component: SplashComponent ,pathMatch: 'full'},
  { path: 'postauth', component: PostauthComponent, pathMatch: 'full' },
  { path: 'launchpad', component: LaunchpadComponent, pathMatch: 'full'},
  { path: 'procedures-bots', component: ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.procedureBotsEnvirpnment.authConfig}},
  { path:'chat-message', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.chatGPTEnvironment.authConfig}},
  { path:'research-bot', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.researchBotEnvirpnment.authConfig}},
  { path:'rotem-engineering-bot', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.rotemEngineeringBotEnvirpnment.authConfig}},
  { path:'agronomist-bot', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.agronomistBotEnvirpnment.authConfig}},
  { path:'patent-bot', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.patentBotEnvirpnment.authConfig}},
  { path:'field-trail-chat', component:ChatWindowComponent, pathMatch: 'full',canActivate: [CheckRoleGuard],data:{env:environment.ProjectsEnvironment.fieldTrailChatEnvirpnment.authConfig}},
  { path: '**', component:PageNotFoundComponent } // Redirect any other routes to the chat window
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
